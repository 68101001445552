<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4 pa-3">
            <psi-toolbar
                :filters="filters"
                :quicklinks="quicklinks"
                :actions="$config('screen.actions')"
                :showActionMenu="selectedItems.length > 0"
                :view-option.sync="viewOption"
                @filter-item="filterItem"
                @filter-clear-all="filterClearAll"
                @quicklink-action="quicklinkAction"
                @refresh="refresh"
            ></psi-toolbar>
            <keep-alive>
                <component
                    :loading="loading"
                    :screens="screens"
                    :screeners="screeners"
                    :is="`screening-${viewOption}`"
                    @item-selected="itemSelected"
                    @toggle-select-all="toggleSelectAll"
                    @action="orderAction"
                ></component>
            </keep-alive>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ScreeningList from "@components/screen/components/ScreeningList";
import ScreeningGrid from "@components/screen/components/ScreeningGrid";
export default {
    name: "screening-history",
    components: {
        ScreeningList,
        ScreeningGrid,
    },
    data() {
        return {
            page: {
                title: "Order History",
                icon: "mdi-account-clock",
            },
            breadcrumbs: [
                {
                    text: "Order History",
                    disabled: true,
                    to: "#",
                },
            ],
            filters: this.$config("screen.filters"),
            quicklinks: this.$config("screen.quicklinks"),
            selectedItems: [],
            viewOption: this.$vuetify.breakpoint.mobile ? "grid" : "list",
        };
    },
    computed: {
        ...mapGetters("Screens", [
            "screens",
            "screenFilters",
            "screenPage",
            "currentScreen",
            "screeners",
            "loading",
        ]),
    },
    watch: {
        screenFilters: {
            immediate: true,
            handler(filter) {
                if (filter && Object.keys(filter).length) {
                    this.updateFilter(
                        "company",
                        this.$_.get(filter, "companies", [])
                    );
                    this.updateFilter(
                        "property",
                        this.$_.get(filter, "properties", [])
                    );
                    this.updateFilter(
                        "assigned_to",
                        this.$_.get(filter, "screeners", [])
                    );
                }
            },
        },
    },
    methods: {
        ...mapActions("Screens", [
            "getScreens",
            "getCurrentScreen",
            "getScreeners",
        ]),
        refresh() {
            // Derive filters and issue filter orders call
            this.filterOrders();
        },
        quicklinkAction(event) {
            switch (event.title) {
                // TODO: change from title based to name based
                case "Pending Orders":
                    // Clear out any set filters
                    this.resetFilters();
                    // Add only this filter from the quicklink
                    this.updateFilter("status", "Pending", "value");
                    // Get the result data
                    this.filterOrders();
                    break;
            }
        },

        itemSelected({ item, value }) {
            if (value) {
                // selected an item
                this.selectedItems.push(item);
            } else {
                // removed an item
                this.selectedItems.splice(
                    this.selectedItems.findIndex(
                        (elem) => elem.order_id === item.order_id
                    ),
                    1
                );
            }
        },
        toggleSelectAll({ items, value }) {
            if (value) {
                this.selectedItems = items;
            } else {
                this.selectedItems = [];
            }
        },
        orderAction(id, item) {
            switch (item.name) {
                case "view-order":
                    this.$router.push({
                        name: "Application Process",
                        params: { id },
                    });
                    break;
            }
        },

        /**
         * Run the result from the filter
         */
        filterOrders() {
            this.getScreens(this.getFilter());
        },
        // Reset Filters to default values
        resetFilters() {
            this.filters.forEach((item) => {
                this.updateFilter(item.name, item.default, "value");
            });
        },
        // update a filter item value on the filters data
        updateFilter(filterName, data, key = "items") {
            const index = this.filters.findIndex(
                (item) => item.name == filterName
            );
            if (index !== -1) {
                let filter = this.filters[index];
                filter[key] = data;
                this.filters.splice(index, 1, filter);
            }
        },
        // set a given item to a filter value
        filterItem(item) {
            this.updateFilter(item.name, item.value, "value");
            this.filterOrders();
        },
        filterClearAll() {
            this.resetFilters();
            this.filterOrders();
        },
        // get a filter structure for the given filters with values
        getFilter() {
            let filter = {};
            this.filters.forEach((item) => {
                if (this.$_.get(item, "value", "").toString().length) {
                    filter[item.name] = {
                        value: this.$_.get(item, "value", ""),
                    };
                }
            });
            return filter;
        },
    },
    mounted() {
        this.filterOrders();
        this.getScreeners();
    },
    created() {},
};
</script>

<style scoped>
</style>