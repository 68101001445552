var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.screens
    ? _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _vm.loading
            ? _c(
                "v-col",
                { staticClass: "mx-auto", attrs: { cols: "12" } },
                [_c("psi-app-loader")],
                1
              )
            : _vm._e(),
          _vm._l(_vm.screens, function(order, index) {
            return [
              !_vm.loading
                ? _c(
                    "v-col",
                    { key: index, attrs: { cols: "12", lg: "3", md: "4" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { color: "grey lighten-3" } },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "white--text text-subtitle-1 secondary darken-1 py-2"
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "white--text mr-2" },
                                [_vm._v("mdi-clipboard-account")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$_.get(order, "applicant.name")) +
                                  " | "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-subtitle-2 font-weight-bold ml-2"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$_.get(order, "applicant.type"))
                                  )
                                ]
                              ),
                              _c("v-spacer"),
                              _c("psi-action-drop-down", {
                                attrs: {
                                  items: _vm.getActions(order),
                                  heading: "Actions - " + order.applicant.name,
                                  icon: "mdi-dots-vertical"
                                },
                                on: {
                                  action: function($event) {
                                    return _vm.$emit(
                                      "action",
                                      order.order_id,
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-card",
                                { staticClass: "my-3 py-1" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font-weight-regular"
                                            },
                                            [_vm._v("Time Pending:")]
                                          ),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ml-3",
                                              attrs: {
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(order.time_pending)
                                              )
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: _vm.$_.get(
                                                  order,
                                                  "status.color"
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$_.get(
                                                      order,
                                                      "status.label"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "mt-2" }),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "accent--text" },
                                            [_vm._v("mdi-account")]
                                          ),
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass:
                                                "font-weight-regular text-subtitle-2 py-2"
                                            },
                                            [
                                              _vm._v("Assigned To: "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ml-2 text-body-2 font-weight-bold"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      order.assigned_to.name
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                color: "secondary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "secondary--text text--darken-2"
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-clipboard-account"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Order ID")])]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "Application Process",
                                                      params: {
                                                        id: order.order_id
                                                      }
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(order.order_id))]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("psi-copy-button", {
                                            attrs: {
                                              value: order.order_id,
                                              label: "Order ID"
                                            }
                                          }),
                                          order.item_new
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "pa-1 ml-1 text-caption",
                                                  attrs: {
                                                    color: "success darken-2",
                                                    small: ""
                                                  }
                                                },
                                                [_vm._v("new")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                color: "secondary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "secondary--text text--darken-2"
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-clipboard-text-multiple"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Group Code")])]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "View Application",
                                                      params: { id: order.code }
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(order.code))]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("psi-copy-button", {
                                            attrs: {
                                              value: order.code,
                                              label: "Group Code"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                color: "secondary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "secondary--text text--darken-2"
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-calendar"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Start Date")])]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(order.created_at))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                color: "secondary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "secondary--text text--darken-2"
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-clipboard-arrow-right"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Source")])]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(order.source))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                color: "secondary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "secondary--text text--darken-2"
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-home-city"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Property / Contact ")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(order.property.name) +
                                            " | " +
                                            _vm._s(
                                              _vm.$_.get(
                                                order,
                                                "property.contact.name",
                                                ""
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-caption font-weight-light"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$_.get(
                                                    order,
                                                    "property.contact.phone",
                                                    ""
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                color: "secondary"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "secondary--text text--darken-2"
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-door-open"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [_c("span", [_vm._v("Unit")])]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { attrs: { title: "Unit" } },
                                        [_vm._v(_vm._s(order.unit))]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }