<template>
    <v-row wrap v-if="screens">
        <v-col cols="12" class="mx-auto" v-if="loading">
            <psi-app-loader></psi-app-loader>
        </v-col>
        <template v-for="(order, index) in screens">
            <v-col v-if="!loading" cols="12" lg="3" md="4" :key="index">
                <v-card color="grey lighten-3">
                    <v-card-title
                        class="white--text text-subtitle-1 secondary darken-1 py-2"
                    >
                        <v-icon class="white--text mr-2"
                            >mdi-clipboard-account</v-icon
                        >
                        {{ $_.get(order, "applicant.name") }} |
                        <span class="text-subtitle-2 font-weight-bold ml-2">{{
                            $_.get(order, "applicant.type")
                        }}</span>
                        <v-spacer></v-spacer>
                        <psi-action-drop-down
                            :items="getActions(order)"
                            :heading="`Actions - ${order.applicant.name}`"
                            icon="mdi-dots-vertical"
                            @action="$emit('action', order.order_id, $event)"
                        ></psi-action-drop-down>
                    </v-card-title>
                    <v-card-text>
                        <v-card class="my-3 py-1">
                            <v-card-text>
                                <div class="d-flex">
                                    <span class="font-weight-regular"
                                        >Time Pending:</span
                                    >
                                    <v-chip small color="primary" class="ml-3">
                                        {{ order.time_pending }}</v-chip
                                    >
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        small
                                        :color="$_.get(order, 'status.color')"
                                    >
                                        {{ $_.get(order, "status.label") }}
                                    </v-chip>
                                </div>
                                <v-divider class="mt-2"></v-divider>
                                <div class="d-flex">
                                    <v-icon class="accent--text"
                                        >mdi-account</v-icon
                                    >
                                    <v-card-title
                                        class="font-weight-regular text-subtitle-2 py-2"
                                        >Assigned To:
                                        <span
                                            class="ml-2 text-body-2 font-weight-bold"
                                            >{{ order.assigned_to.name }}</span
                                        >
                                    </v-card-title>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-clipboard-account</v-icon
                                            >
                                        </template>
                                        <span>Order ID</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span>
                                        <router-link
                                            :to="{
                                                name: 'Application Process',
                                                params: { id: order.order_id },
                                            }"
                                            >{{ order.order_id }}</router-link
                                        >
                                        <!-- <a href="">{{ item.order_id }}</a> -->
                                    </span>
                                    <psi-copy-button
                                        :value="order.order_id"
                                        label="Order ID"
                                    ></psi-copy-button>
                                    <v-chip
                                        v-if="order.item_new"
                                        color="success darken-2"
                                        small
                                        class="pa-1 ml-1 text-caption"
                                        >new</v-chip
                                    >
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-clipboard-text-multiple</v-icon
                                            >
                                        </template>
                                        <span>Group Code</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span>
                                        <router-link
                                            :to="{
                                                name: 'View Application',
                                                params: { id: order.code },
                                            }"
                                            >{{ order.code }}</router-link
                                        >
                                    </span>
                                    <psi-copy-button
                                        :value="order.code"
                                        label="Group Code"
                                    ></psi-copy-button>
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-calendar</v-icon
                                            >
                                        </template>
                                        <span>Start Date</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>{{
                                    order.created_at
                                }}</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-clipboard-arrow-right</v-icon
                                            >
                                        </template>
                                        <span>Source</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title>{{
                                    order.source
                                }}</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-home-city</v-icon
                                            >
                                        </template>
                                        <span>Property / Contact </span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title
                                    >{{ order.property.name }} |
                                    {{
                                        $_.get(
                                            order,
                                            "property.contact.name",
                                            ""
                                        )
                                    }}
                                    <br />
                                    <span
                                        class="text-caption font-weight-light"
                                    >
                                        {{
                                            $_.get(
                                                order,
                                                "property.contact.phone",
                                                ""
                                            )
                                        }}</span
                                    >
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-tooltip top color="secondary">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                class="secondary--text text--darken-2"
                                                >mdi-door-open</v-icon
                                            >
                                        </template>
                                        <span>Unit</span>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-title title="Unit">{{
                                    order.unit
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>
<script>
import lib from "../library";

export default {
    name: "screening-grid",
    components: {},
    props: {
        screens: {
            type: Array,
            required: true,
        },
        screeners: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        getActions(order) {
            return lib.getActions(order);
        },
    },
};
</script>

<style scoped>
</style>