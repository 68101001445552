var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      "single-select": false,
      "item-key": "order_id",
      "show-select": "",
      headers: _vm.headers,
      items: _vm.screens,
      "items-per-page": 10,
      loading: _vm.loading,
      "loading-text": "Loading ... please wait",
      "loader-height": 6,
      "hide-default-footer": _vm.loading,
      "disable-pagination": ""
    },
    on: {
      "toggle-select-all": function($event) {
        return _vm.$emit("toggle-select-all", $event)
      },
      "item-selected": function($event) {
        return _vm.$emit("item-selected", $event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item.status.label",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("v-chip", {
              attrs: { small: "", color: _vm.$_.get(item, "status.color", "") },
              domProps: {
                textContent: _vm._s(_vm.$_.get(item, "status.label", ""))
              }
            })
          ]
        }
      },
      {
        key: "item.applicant",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "d-flex flex-column my-1" }, [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium alternate1--text text--darken-1"
                },
                [_vm._v(_vm._s(item.applicant.name))]
              ),
              _c("div", [
                _c("span", { staticClass: "font-weight-light" }, [
                  _vm._v("Type:")
                ]),
                _c("span", { staticClass: "font-weight-medium ml-1" }, [
                  _vm._v(_vm._s(item.applicant.type))
                ])
              ])
            ])
          ]
        }
      },
      {
        key: "item.order_id",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "span",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "Application Process",
                        params: { id: item.order_id }
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.order_id))]
                )
              ],
              1
            ),
            _c("psi-copy-button", {
              attrs: { value: item.order_id, label: "Order ID" }
            }),
            item.item_new
              ? _c(
                  "v-chip",
                  {
                    staticClass: "pa-1 ml-1 text-caption",
                    attrs: { color: "success darken-2", small: "" }
                  },
                  [_vm._v("new")]
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "item.code",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "span",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "View Application",
                        params: { id: item.code }
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.code))]
                )
              ],
              1
            ),
            _c("psi-copy-button", {
              attrs: { value: item.code, label: "Application Code" }
            })
          ]
        }
      },
      {
        key: "item.property",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "d-flex flex-column my-1" }, [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium alternate1--text text--darken-1"
                },
                [
                  _vm._v(
                    _vm._s(item.property.name) + " | " + _vm._s(item.unit) + " "
                  )
                ]
              ),
              _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$_.get(item, "property.contact.name", "")))
                ]),
                _vm._v(" | "),
                _c("span", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "tel:#" + _vm.$_.get(item, "property.contact.phone")
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$_.get(item, "property.contact.phone", ""))
                      )
                    ]
                  )
                ])
              ])
            ])
          ]
        }
      },
      {
        key: "item.created_at",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "d-flex flex-column my-3" }, [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium alternate1--text text--darken-1"
                },
                [_vm._v(_vm._s(item.created_at))]
              ),
              item.status.label != "Completed"
                ? _c("div", [
                    _c(
                      "span",
                      { staticClass: "font-weight-light text-caption" },
                      [_vm._v("Pending:")]
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "ml-2 text-caption",
                            attrs: { small: "", color: "primary " }
                          },
                          [_vm._v(_vm._s(item.time_pending))]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ]
        }
      },
      {
        key: "item.assigned_to",
        fn: function(props) {
          return [
            _c(
              "v-edit-dialog",
              {
                attrs: {
                  "return-value": props.item.assigned_to,
                  persistent: "",
                  large: ""
                },
                on: {
                  "update:returnValue": function($event) {
                    return _vm.$set(props.item, "assigned_to", $event)
                  },
                  "update:return-value": function($event) {
                    return _vm.$set(props.item, "assigned_to", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "input",
                      fn: function() {
                        return [
                          _c("v-select", {
                            ref: "screeners-" + props.item.assigned_to,
                            attrs: {
                              items: _vm.screeners,
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: props.item.assigned_to.id,
                              callback: function($$v) {
                                _vm.$set(props.item.assigned_to, "id", $$v)
                              },
                              expression: "props.item.assigned_to.id"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" " + _vm._s(props.item.assigned_to.name) + " "),
                _c(
                  "v-btn",
                  { staticClass: "ml-1", attrs: { icon: "", small: "" } },
                  [
                    _c("v-icon", { attrs: { small: "", color: "primary" } }, [
                      _vm._v("mdi-pencil")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("psi-action-drop-down", {
              attrs: {
                heading: "Actions - " + item.applicant.name,
                items: _vm.getActions(item)
              },
              on: {
                action: function($event) {
                  return _vm.$emit("action", item.order_id, $event)
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }