import get from "lodash.get";
export default {
    /**
     * Function getActions - gets an action list to execute against for the screening orders
     *
     * @param {*} item Order returned from order list
     */
    getActions(item) {
        return [
            {
                name: "view-order",
                title: `View ${item.applicant.name} Screen`,
                icon: "mdi-clipboard-account",
            },
            {
                name: "download-pdf",
                title: "Download PDF",
                icon: "mdi-file-pdf",
            },
            {
                name: "send-fax",
                title: `Send Fax to ${item.property?.contact?.name}`,
                icon: "mdi-fax",
            },
            {
                name: "share-link",
                title: `Share Screening Link`,
                icon: "mdi-share-variant",
            },
        ];
    },
};
