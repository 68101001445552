<template>
    <v-data-table
        :single-select="false"
        item-key="order_id"
        show-select
        :headers="headers"
        :items="screens"
        :items-per-page="10"
        :loading="loading"
        loading-text="Loading ... please wait"
        :loader-height="6"
        :hide-default-footer="loading"
        disable-pagination
        @toggle-select-all="$emit('toggle-select-all', $event)"
        @item-selected="$emit('item-selected', $event)"
    >
        <template v-slot:item.status.label="{ item }">
            <v-chip
                small
                :color="$_.get(item, 'status.color', '')"
                v-text="$_.get(item, 'status.label', '')"
            ></v-chip>
        </template>
        <template v-slot:item.applicant="{ item }">
            <div class="d-flex flex-column my-1">
                <span
                    class="font-weight-medium alternate1--text text--darken-1"
                    >{{ item.applicant.name }}</span
                >
                <div>
                    <span class="font-weight-light">Type:</span>
                    <span class="font-weight-medium ml-1">{{
                        item.applicant.type
                    }}</span>
                </div>
            </div>
        </template>
        <template v-slot:item.order_id="{ item }">
            <span>
                <router-link
                    :to="{
                        name: 'Application Process',
                        params: { id: item.order_id },
                    }"
                    >{{ item.order_id }}</router-link
                >
                <!-- <a href="">{{ item.order_id }}</a> -->
            </span>
            <psi-copy-button
                :value="item.order_id"
                label="Order ID"
            ></psi-copy-button>
            <v-chip
                v-if="item.item_new"
                color="success darken-2"
                small
                class="pa-1 ml-1 text-caption"
                >new</v-chip
            >
        </template>
        <template v-slot:item.code="{ item }">
            <span>
                <router-link
                    :to="{
                        name: 'View Application',
                        params: { id: item.code },
                    }"
                    >{{ item.code }}</router-link
                >
            </span>
            <psi-copy-button
                :value="item.code"
                label="Application Code"
            ></psi-copy-button>
        </template>

        <template v-slot:item.property="{ item }">
            <div class="d-flex flex-column my-1">
                <span class="font-weight-medium alternate1--text text--darken-1"
                    >{{ item.property.name }} | {{ item.unit }}
                </span>
                <div>
                    <span>{{ $_.get(item, "property.contact.name", "") }}</span>
                    |
                    <span>
                        <a
                            :href="`tel:#${$_.get(
                                item,
                                'property.contact.phone'
                            )}`"
                            >{{ $_.get(item, "property.contact.phone", "") }}</a
                        >
                    </span>
                </div>
            </div>
        </template>
        <template v-slot:item.created_at="{ item }">
            <div class="d-flex flex-column my-3">
                <span
                    class="font-weight-medium alternate1--text text--darken-1"
                    >{{ item.created_at }}</span
                >
                <div v-if="item.status.label != 'Completed'">
                    <span class="font-weight-light text-caption">Pending:</span>
                    <span>
                        <v-chip
                            small
                            class="ml-2 text-caption"
                            color="primary "
                            >{{ item.time_pending }}</v-chip
                        >
                    </span>
                </div>
            </div>
        </template>
        <template v-slot:item.assigned_to="props">
            <v-edit-dialog
                :return-value.sync="props.item.assigned_to"
                persistent
                large
            >
                {{ props.item.assigned_to.name }}
                <v-btn icon small class="ml-1">
                    <v-icon small color="primary">mdi-pencil</v-icon>
                </v-btn>
                <template v-slot:input>
                    <v-select
                        :ref="`screeners-${props.item.assigned_to}`"
                        v-model="props.item.assigned_to.id"
                        :items="screeners"
                        item-text="name"
                        item-value="id"
                    ></v-select>
                </template>
            </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
            <psi-action-drop-down
                :heading="`Actions - ${item.applicant.name}`"
                :items="getActions(item)"
                @action="$emit('action', item.order_id, $event)"
            ></psi-action-drop-down>
        </template>
    </v-data-table>
</template>
<script>
import lib from "../library";

export default {
    name: "screening-list",
    components: {},
    props: {
        screens: {
            type: Array,
            required: true,
        },
        screeners: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            actions: [
                {
                    title: "View Screen",
                    icon: "mdi-clipboard-account",
                },
                {
                    title: "View Screen",
                    icon: "mdi-clipboard-account",
                },
            ],
            headers: [
                {
                    text: "Order ID",
                    align: "start",
                    sortable: true,
                    value: "order_id",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Start Date",
                    align: "start",
                    sortable: true,
                    value: "created_at",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Status",
                    align: "start",
                    sortable: true,
                    value: "status.label",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Source",
                    align: "start",
                    sortable: true,
                    value: "source",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Applicant",
                    align: "start",
                    sortable: false,
                    value: "applicant",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Group Code",
                    align: "start",
                    sortable: true,
                    value: "code",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Property / Unit",
                    align: "start",
                    sortable: false,
                    value: "property",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Assigned To",
                    align: "start",
                    sortable: true,
                    value: "assigned_to",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Actions",
                    align: "start",
                    sortable: false,
                    value: "actions",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        getActions(item) {
            return lib.getActions(item);
        },
    },
};
</script>

<style scoped>
</style>