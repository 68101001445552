var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4 pa-3" },
        [
          _c("psi-toolbar", {
            attrs: {
              filters: _vm.filters,
              quicklinks: _vm.quicklinks,
              actions: _vm.$config("screen.actions"),
              showActionMenu: _vm.selectedItems.length > 0,
              "view-option": _vm.viewOption
            },
            on: {
              "update:viewOption": function($event) {
                _vm.viewOption = $event
              },
              "update:view-option": function($event) {
                _vm.viewOption = $event
              },
              "filter-item": _vm.filterItem,
              "filter-clear-all": _vm.filterClearAll,
              "quicklink-action": _vm.quicklinkAction,
              refresh: _vm.refresh
            }
          }),
          _c(
            "keep-alive",
            [
              _c("screening-" + _vm.viewOption, {
                tag: "component",
                attrs: {
                  loading: _vm.loading,
                  screens: _vm.screens,
                  screeners: _vm.screeners
                },
                on: {
                  "item-selected": _vm.itemSelected,
                  "toggle-select-all": _vm.toggleSelectAll,
                  action: _vm.orderAction
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }